<template>
    <header class="header" :class="{ 'shadow': isHeaderShadow }">
    <div class="navbarhome">
      <div class="navhome">
        <div class="navbar-brandhome">
          <div class="logo">
            <router-link to="/">
              <img src="../../public/images/logo1.png" alt="logo" class="logo">
            </router-link>
          </div>
        </div>
        <button class="navbar-toggler" type="button" @click="toggleNavbar">
          <i class="fa fa-bars" aria-hidden="true"></i>
        </button>

        <div class="navbar-collapse" :class="{ 'active': isNavbarActive }">
          <ul class="navItem">
            <li class="navLink" v-if="isLogged">
              <router-link to="/app-list">DASHBOARD</router-link>
            </li>          
            <li class="navLink">
              <a href="https://docs.shortcodeafrica.com" target="_blank">DOCS</a>
            </li>
            <li class="navLink">
              <router-link to="/about">ABOUT</router-link>
            </li>
            <li class="navLink">
              <router-link to="/pricing">PRICING</router-link>
            </li>
            <li class="navLink">
              <router-link to="/contact">CONTACT</router-link>
            </li>
            <li class="navLink">
              <router-link to="/faq">FAQ</router-link>
            </li>
            <li class="navLink" v-if="!isLogged">
              <router-link to="/login" class="btn btn-warning mt-0" style="background-color:#f9b150; color:#000; width:120px; padding:10px; border:none; border-radius:20px;font-weight:bold">
                <span class="menu-title">Login</span>
              </router-link>
              <router-link to="/register" class="btn btn-warning ml-2 mt-0" style="background-color:#08dd7c; color:#000; width:120px; padding:10px; border:none; border-radius:20px;font-weight:bold">
                <span class="menu-title">Signup</span>
              </router-link>
            </li>
            <li class="navLink" v-if="isLogged">
              <router-link to="/app-list" class="btn btn-warning mt-0" style="background-color:#f9b150; color:#000; width:120px; padding:10px; border:none; border-radius:20px;font-weight:bold">
                <span class="menu-title">Dashboard</span>
              </router-link>
              <router-link to="/logout" class="btn btn-warning ml-2 mt-0" style="background-color:#ff6b6b; color:#000; width:120px; padding:10px; border:none; border-radius:20px;font-weight:bold">
                <span class="menu-title">Logout</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  data() {
    return {
      isNavbarActive: false,
      isHeaderShadow: false,
      isLogged: false
    };
  },
  mounted() {
    this.checkUserLoggedIn();
    window.addEventListener("scroll", this.handleScroll);
    document.addEventListener("click", this.handleOutsideClick);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    document.removeEventListener("click", this.handleOutsideClick);
  },
  methods: {
    toggleNavbar() {
      this.isNavbarActive = !this.isNavbarActive;
    },
    handleOutsideClick(event) {
      const navbarCollapse = this.$el.querySelector(".navbar-collapse");
      const navbarToggler = this.$el.querySelector(".navbar-toggler");

      if (
        !navbarCollapse.contains(event.target) &&
        !navbarToggler.contains(event.target)
      ) {
        this.isNavbarActive = false;
      }
    },
    handleScroll() {
      const header = this.$el.querySelector(".header");
      if (window.pageYOffset > 0) {
        this.isHeaderShadow = true;
      } else {
        this.isHeaderShadow = false;
      }
    },
    checkUserLoggedIn() {
      this.isLogged = !!localStorage.getItem('token');
    },
  },
};
</script>
<style scoped>
.header {
  background-color: #2a3342;
  color: #fff;
}

.navbarhome {
  width: 80%;
  margin: 0 auto;
}

.logo {
  width: 100px;
  height: 100px;
  margin-left: 10px;
  object-fit: contain;
}

.navhome {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}


.navItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.navLink {
  color: #1a1e23;
  font-size: 16px;
  margin: 0 20px;
  list-style: none;
  display: flex;
  flex-direction: row;
}

.navLink a {
  text-decoration: none;
  color: #b8b8b8;
}

.navLink a:hover {
  color: #fff;
}

.navbar-toggler {
  display: none;
}

.navbar-collapse {
  display: flex;
  flex-direction: row;
}

@media (max-width: 767px) {
  .header{
        padding: 10px;
        width: 100%;
        position: fixed;
        background-color: #2a3342;
        z-index: 999;
    }
    .header.shadow {
        box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
      }
    .navhome {
        display: block;
        width: 100%;
    }
    .logo{
        width: 50px;
        height: 50px;
        object-fit: contain;
    }

  .navbar-toggler {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    color: #000;
    background: none;
    border: none;
    width: 50px;
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 20px;
  }

  .navbar-collapse {
        display: flex;
        flex-direction: row;
        height: 0;
        overflow: hidden;
        transition: height 0.3s ease-in-out;
        width: 100%;
      }

      .navbar-collapse.active {
        height: auto;
        margin-top: 25px;
        width: 100% !important;
      }

      .navItem{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }

    .navLink {
      display: block;
      margin: 10px 0;
    }

}</style>
